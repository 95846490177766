<template>
  <div class="home">
    <div class="all">
      <div class="info-top">
        <div class="box-img">
          <img :src="detail.picture" alt="">
        </div>
        <div class="box-info">
          <div class="info-title">
            <span>{{ detail.name }}</span>
          </div>
          <div class="info-table">
            <div class="box-s">
              <span>地址：</span>
              <span>{{ detail.location }}</span>
            </div>
            <div class="box-s">
              <span>个人简介：</span>
              <span>{{ detail.selfIntroduction }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="info-groups">
        <div class="info-title">
          <span>个人简介</span>
        </div>
        <div class="info-txt">
          <div>{{detail.selfIntroduction}}</div>
        </div>
      </div>

      <div class="info-groups" v-if="detail.personalResume">
        <div class="info-title">
          <span>个人简历</span>
        </div>
        <div class="info-txt">
          <div>{{detail.personalResume}}</div>
        </div>
      </div>

      <div class="info-groups">
        <div class="info-title">
          <span>人物文章</span>
        </div>
        <div class="info-txt">
          <div>{{detail.characterEssay}}</div>
        </div>
      </div>
      <!-- <div class="name">{{ detail.name }}</div>
      <div class="content">
        <div class="left">
          <div class="location">{{ detail.location }}</div>
          <div class="title">个人简介</div>
          <div class="intro">{{ detail.selfIntroduction }}</div>
          <div class="title title2">代表论著</div>
        </div>
        <img :src="detail.picture" alt="">
      </div>

      <div class="duction" v-html="detail.personageIntroduction"></div> -->
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  name: '',
  components: {

  },
  // 定义属性
  data() {
    return {
      detail: {}
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    init() { }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.$route.query.detail) {
      let formObj = decodeURIComponent(this.$route.query.detail)
      this.detail = JSON.parse(formObj)
      console.log('参数', this.detail.personageIntroduction);

    }

    this.init()
  },
}
</script>

<style lang="scss" scoped>
.home {
  background: #EDF4F8;
  box-sizing: border-box;
  padding-top: 25px;
  padding-bottom: 80px;
  overflow-y: auto;
}

.all {
  width: 73%;
  margin: 0 auto;
  background: #FFFFFF;
  padding: 30px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);

  .info-top {
    border-top: 3px solid #1546B0;
    padding: 30px 30px 30px 30px;
    margin-bottom: 45px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    min-height: 280px;

    .box-img {
      position: static;
      float: right;
      margin-left: 30px;
      width: 162px;
      height: 230px;

      img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
    }

    .box-info {
      overflow: hidden;

      .info-title {
        border-bottom: 1px solid #F0F2F3;
        padding-bottom: 13px;
        margin-bottom: 25px;
        color: #1546B0;
        // margin-right: 20px;
        font-size: 24px;
      }

      .info-table {
        display: table;
        width: 100%;

        .box-s {
          float: left;
          width: 100%;
          font-size: 16px;
          color: #333333;
          margin-bottom: 24px;
          padding-right: 10px;
          color: #333;
        }
      }
    }
  }

  .info-groups {
    margin-bottom: 25px;

    .info-title {
      font-size: 18px;
      background: none;
      border-left: none;
      padding: 10px 0px;
      border-bottom: 1px dashed #ECECEC;
      color: #1958AF;
      margin-bottom: 20px;
      font-weight: bold;
      color: #333333;

      span {
        position: relative;
        &::after {
          position: absolute;
          content: '';
          height: 2px;
          background: #1546B0;
          left: 0;
          right: 0;
          bottom: -12px;
        }
      }
    }

    .info-txt {
      font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    }

  }
}
</style>